import React from 'react';
import PlayerControls from '../../components/PlayerControls/PlayerControls';

const Player: React.FC<{ lang: "en" | "ru" }> = ({ lang }) => {
    return (
        <div style={{ backgroundImage: "url(../../assets/img/player-background.png)" }}>
            <PlayerControls lang={lang} />
        </div>
    );
};

export default Player;
