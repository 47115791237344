import React, { createContext, useContext, ReactNode } from 'react';

const translations = {
    en: {
        menu: 'Menu',
        session_ended: 'Your session has ended. Please reload the page.',
        something_wrong: 'Something went wrong. Please try reloading the page.',
        params_error: 'Failed to get parameters. Please try reloading the page.',
        stations: 'Stations',
        no_stations: 'There are currently no available radio stations. We apologize for the inconvenience.',
        invite_friends: 'Invite Your Friends',
        your_referral_link: 'Your referral link',
        play: 'Play',
        pause: 'Pause',
        next: 'Next',
        previous: 'Previous',
        volume: 'Volume',
        favorite: 'Favorite',
        unfavorite: 'Unfavorite',
        favorites: 'Favorites',
        no_favorites: 'You currently have no favorite radio stations. You can add a radio station to your favorites list in the "Radio" tab.',
        copyright: 'Copyright',
        copyright_notice: 'Copyright Infringement Notice',
        copyright_text1: 'Radiogram does not engage in its own broadcasting or retransmission of radio station audio streams. All published materials (links to audio streams and contact information) are taken from open sources on the Internet or were sent by application users. The broadcasting occurs on third-party servers with special software for this purpose.',
        copyright_text2: 'Logos (trademarks) that have been officially registered belong to their owners. Radiogram uses such materials for informational purposes.',
        copyright_text3: 'Radiogram respects the intellectual property rights of others and expects its users to do the same. If you are a copyright owner or are authorized to act on their behalf, please report any alleged copyright infringements.',
        copyright_text4: 'Identify the material that you believe infringes copyright by providing convincing evidence. After receiving the notice, Radiogram will promptly respond to copyright infringement claims and take any action it deems appropriate, including removing the disputed material from the application. To notify, use the feedback form. (<a href="https://t.me/RADIOGRAM_feedback_bot">https://t.me/RADIOGRAM_feedback_bot</a>)',
        radio: 'Radio',
        balance_title: 'Balance:',
        balance_subtitle: 'IMPULSE',
        referral_bonus: '+1000 IMPULSE FOR EACH FRIEND YOU INVITE,',
        referral_bonus_2: '+5% OF YOUR FRIENDS\' EARNINGS.',
        no_referrals: 'Your friends have not joined you yet.',
        player: 'Player',
        friends: 'Friends',
        bonus_coming_soon: 'BONUS (coming soon)',
        feedback: 'Feedback',
        search: 'Search',
        all: 'All',
        genres: 'Genres',
        countries: 'Countries',
        no_options: 'No available options yet'
    },
    ru: {
        menu: 'Меню',
        session_ended: 'Ваша сессия завершена. Перезапустите страницу.',
        something_wrong: 'Что-то пошло не так. Попробуйте перезапустить страницу.',
        params_error: 'Не удалось получить параметры. Попробуйте перезапустить страницу.',
        stations: 'Станции',
        no_stations: 'На данный момент доступных радиостанций нет. Приносим свои извинения.',
        invite_friends: 'Пригласите Ваших друзей',
        your_referral_link: 'Ваша реферальная ссылка',
        play: 'Играть',
        pause: 'Пауза',
        next: 'Следующий',
        previous: 'Предыдущий',
        volume: 'Громкость',
        favorite: 'Избранное',
        unfavorite: 'Убрать из избранного',
        favorites: 'Избранное',
        no_favorites: 'На данный момент у Вас нет избранных радиостанций. Вы можете добавить радиостаннцию в список избранного во вкладке "Радио".',
        copyright: 'Правообладателям',
        copyright_notice: 'Уведомление о нарушении авторских прав',
        copyright_text1: 'Radiogram не занимается собственной трансляцией или ретрансляций аудиопотоков радиостанций. Все опубликованные материалы (ссылки на аудиопотоки и контактная информация) взяты из открытых источников в сети интернет или были присланы пользователями приложения. Вещания происходит на сторонних серверах со специальным для этого программным обеспечением.',
        copyright_text2: 'Логотипы (товарные знаки) прошедшие официальную регистрацию, принадлежат их владельцам. Radiogram использует такие материалы в информационных целях.',
        copyright_text3: 'Radiogram уважает права интеллектуальной собственности других лиц и ожидает что ее пользователи будут делать то же самое. Если вы являетесь владельцем авторских прав или уполномочены действовать от их имени, пожалуйста, сообщите о предполагаемых нарушениях авторских прав.',
        copyright_text4: 'Укажите материал, который, по вашему мнению, нарушает авторские права предъявив убедительные доказательства. После получения уведомления, Radiogram оперативно отреагирует на заявления о нарушении авторского права, предпримет любые действия по своему усмотрению, в том числе удалит оспариваемый материал с приложения. Для уведомления воспользуйтесь формой обратной связи. (<a href="https://t.me/RADIOGRAM_feedback_bot">https://t.me/RADIOGRAM_feedback_bot</a>)',
        radio: 'Радио',
        balance_title: 'Balance:',
        balance_subtitle: 'IMPULSE',
        referral_bonus: '+1000 ИМПУЛЬСОВ ЗА КАЖДОГО ПРИВЕДЕННОГО ДРУГА, ',
        referral_bonus_2: '+5% ОТ ЗАРАБОТКА ВАШИХ ДРУЗЕЙ.',
        no_referrals: 'Ваши друзья еще не присоединились к вам.',
        player: 'Плеер',
        friends: 'Друзья',
        bonus_coming_soon: 'БОНУС (скоро)',
        feedback: 'Обратная связь',
        search: 'Поиск',
        all: 'Все',
        genres: 'Жанры',
        countries: 'Страны',
        no_options: 'Доступных опций еще нет'
    }
};

type Translations = typeof translations.en;

const I18nContext = createContext<Translations>(translations.en);

interface I18nProviderProps {
    lang: keyof typeof translations;
    children: ReactNode;
}

export const I18nProvider: React.FC<I18nProviderProps> = ({ lang, children }) => {
    return (
        <I18nContext.Provider value={translations[lang] || translations.en}>
            {children}
        </I18nContext.Provider>
    );
};

export const useTranslate = () => {
    const context = useContext(I18nContext);
    if (!context) {
        throw new Error('useTranslate must be used within an I18nProvider');
    }
    return context;
};

export const translate = (key: keyof Translations, lang: keyof typeof translations): string => {
    const t = translations[lang] || translations.en;
    return t[key] || key;
};
