import React, { useEffect } from 'react';
import { useFavorites } from "../../context/FavoritesContext";
import { useUser } from "../../context/UserContext";
import { useStations } from "../../context/StationsContext";
import { getAllFavoritesByTelegramId } from "../../service/favorites/favorites";
import { ReactComponent as NoteIcon } from "../../assets/icons/noteIcon.svg";
import { ReactComponent as FavoriteActiveIcon } from "../../assets/icons/favoriteActiveIcon.svg";
import { ReactComponent as FavoriteIcon } from "../../assets/icons/favoriteIcon.svg";
import styles from "../stations/stations.module.css";
import { useTranslate } from '../../i18n';

const Favorites: React.FC<{ setStation: (newVal: number) => void, lang: "en" | "ru" }> = ({ setStation, lang }) => {
    const { user } = useUser();
    const { favorites, handleGetFavoritesByUser, handleToggleFavorite } = useFavorites();
    const { station: activeStation, handleSelectStation } = useStations();
    const translate = useTranslate();

    useEffect(() => {
        if (user) {
            handleGetFavoritesByUser(user.telegram_id);
        }
    }, [user]);

    return (
        <div style={{ width: "100%", height: "100%"}}>
            <h1 style={{ textAlign: "center" }}>{translate.favorites}</h1>
            <div style={{ display: "flex", maxHeight: "calc(100% - 90px)", overflowY: "auto", flexDirection: "column", gap: "16px" }}>
                {Array.isArray(favorites) && !!favorites.length ? (
                    favorites.map((station) => (
                        <div onClick={() => {
                            handleSelectStation(station.id.toString(), lang);
                            setStation(0);
                        }} className={`${styles.itemWrapper} ${station.id === activeStation?.id ? styles.active : ""}`} key={station.id}>
                            <button className={styles.playButton} >
                                <NoteIcon />
                            </button>
                            <div className={styles.infoWrapper}>
                                <p className={styles.title}>{station.name}</p>
                                <p className={styles.description}>{station.description}</p>
                            </div>
                            <div className={styles.favoriteIcon} onClick={async () => {
                                try {
                                    await handleToggleFavorite(station?.id.toString(), user?.telegram_id);
                                    await getAllFavoritesByTelegramId(user?.telegram_id);
                                } catch (error) {
                                    console.error(error);
                                }
                            }}>
                                {Array.isArray(favorites) && favorites.find(fav => fav.id === station.id) ? (
                                    <FavoriteActiveIcon />
                                ) : (
                                    <FavoriteIcon />
                                )}
                            </div>
                        </div>
                    ))
                ) : (<div>{translate.no_favorites}</div>)}
            </div>
        </div>
    );
};

export default Favorites;
