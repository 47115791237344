import React, { useEffect, useState } from 'react';
import { initSwipeBehavior } from '@telegram-apps/sdk';
import { initClosingBehavior } from '@telegram-apps/sdk';
import { initViewport } from '@telegram-apps/sdk';

import TabNavigation from './components/TabNavigation/TabNavigation';
import Menu from "./components/Menu/Menu";
import Player from './pages/player/Player';
import Stations from './pages/stations/Stations';
import Favorites from './pages/favorites/Favorites';
import Copyright from "./pages/copyright/Copyright";
import RefLink from "./pages/refLink/RefLink";
import { BASE_ORIGIN } from "./service/axiosConfig/axiosConfig";
import { I18nProvider, translate } from './i18n';
import {useClosingBehavior, useSwipeBehavior, useViewport} from "@telegram-apps/sdk-react";

const App: React.FC = () => {
    // const swipeBehavior = useSwipeBehavior();
    // const closingBehavior = useClosingBehavior();
    // const viewport = useViewport();

    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(0);
    const [error, setError] = useState<string | null>(null);
    const [lang, setLang] = useState('en');

    const handleMenuOpen = () => setOpen(true);
    const handleMenuClose = () => setOpen(false);
    const handleSetValue = (newVal: number) => setValue(newVal);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const telegramId = urlParams.get('telegram_id');
        // @ts-ignore
        const sessionId = window?.Telegram?.WebApp?.initDataUnsafe?.auth_date;
        // @ts-ignore
        const device = window?.Telegram?.WebApp?.platform;
        const langParam = urlParams.get('lang') || 'en';
        setLang(langParam);

        if (telegramId && sessionId && device) {
            const ws = new WebSocket(`wss://${BASE_ORIGIN}/ws/users/${telegramId}/${sessionId}/`);

            ws.onopen = (event) => {
                console.log("Connection set")
                console.log(event)
            };

            ws.onclose = (event) => {
                setError(translate('session_ended', lang as "en" | "ru"));
            };

            ws.onerror = (error) => {
                console.log(error)
                setError(translate('something_wrong', lang as "en" | "ru"));
            };

            return () => {};
        } else {
            setError(translate('params_error', lang as "en" | "ru"));
        }
    }, []);

    // useEffect(() => {
    //     if (viewport) {
    //         viewport.expand();
    //     }
    //
    //     if (swipeBehavior) {
    //         swipeBehavior.disableVerticalSwipe();
    //         console.log(swipeBehavior.isVerticalSwipeEnabled);
    //     }
    //
    //     if (closingBehavior) {
    //         closingBehavior.disableConfirmation();
    //         console.log(closingBehavior.isConfirmationNeeded);
    //     }
    //
    //     document.documentElement.style.setProperty('--tg-viewport-height', '100vh');
    // }, [viewport, swipeBehavior, closingBehavior]);

    if (error) {
        return (
            <div className="error-container">
                <h2 style={{ color: "#ffffff", textAlign: "center", lineHeight: "36px" }}>{error}</h2>
            </div>
        );
    }

    return (
        <I18nProvider lang={lang as "en" | "ru"}>
            <div className={"wrapper"}>
                {open && <Menu lang={lang as "en" | "ru"} onTabChange={handleSetValue} onClose={handleMenuClose} />}

                <div style={{ display: (value === 0) ? "block" : "none", height: "max-content", width: "100%" }}>
                    <Player lang={lang as "en" | "ru"} />
                </div>
                {value === 1 && (
                    <div style={{ height: "calc(100% - 200px)", width: "100%" }}>
                        <Stations lang={lang as "en" | "ru"} setStation={handleSetValue} />
                    </div>
                )}
                {value === 2 && (
                    <div style={{ height: "calc(100% - 90px)", width: "100%" }}>
                        <Favorites lang={lang as "en" | "ru"} setStation={handleSetValue} />
                    </div>
                )}
                {value === 3 && (
                    <Copyright lang={lang as "en" | "ru"} />
                )}
                {value === 4 && (
                    <RefLink lang={lang as "en" | "ru"} />
                )}
                <TabNavigation menuOpen={open} onMenuOpen={handleMenuOpen} lang={lang as "en" | "ru"} value={value} setValue={setValue} />
            </div>
        </I18nProvider>
    );
};

export default App;
