import React from 'react';

import { ReactComponent as PlayerIcon } from '../../assets/icons/radioIcon.svg';
import { ReactComponent as FavoritesIcon } from '../../assets/icons/favoritesIcon.svg';
import { ReactComponent as StationsIcon } from '../../assets/icons/stationsIcon.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/menuIcon.svg';

import { useTranslate } from '../../i18n';

import styles from "./tabNavigation.module.css";

const TabNavigation: React.FC<{ value: number, menuOpen: boolean,  onMenuOpen: () => void; setValue: React.Dispatch<React.SetStateAction<number>>, lang: "ru" | "en" }> = ({ value, setValue, onMenuOpen, menuOpen, lang }) => {
    const translate = useTranslate();

    const handleChange = (newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.bottomNavigation}>
                <div onClick={() => handleChange(0)} className={`${styles.navigationItem} ${value === 0 && !menuOpen && styles.activeNavigationItem}`}>
                    <PlayerIcon />
                    <span className={styles.navigationTitle}>
                    {translate.radio}
                </span>
                </div>
                <div onClick={() => handleChange(1)} className={`${styles.navigationItem} ${value === 1 && !menuOpen && styles.activeNavigationItem}`}>
                    <StationsIcon />
                    <span className={styles.navigationTitle}>
                    {translate.stations}
                </span>
                </div>
                <div onClick={() => handleChange(2)} className={`${styles.navigationItem} ${value === 2 && !menuOpen && styles.activeNavigationItem}`}>
                    <FavoritesIcon />
                    <span className={styles.navigationTitle}>
                    {translate.favorites}
                </span>
                </div>
                <div onClick={onMenuOpen} className={`${styles.navigationItem} ${menuOpen && styles.activeNavigationItem}`}>
                    <MenuIcon />
                    <span className={styles.navigationTitle}>
                    {translate.menu}
                </span>
                </div>
            </div>
        </div>
    );
};

export default TabNavigation;
