import React, { FC, useState, useEffect, useRef } from 'react';
import styles from "./filter.module.css";
import {translate} from "../../../i18n";

interface FilterProps {
    title: string;
    activeFilter: number;
    options: any[];
    position: string;
    onOptionSelect: (newVal: number) => void;
    lang: "en" | "ru";
}

const Filter: FC<FilterProps> = ({ options, onOptionSelect, title, activeFilter, position, lang }) => {
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.optionWrapper} ref={ref}>
            <button onClick={() => setOpen(prev => !prev)} className={styles.title}>{title}</button>
            {open && (
                <ul className={`${styles.options} ${position === "left" ? styles.left : styles.right}`}>
                    <div onClick={() => {
                        onOptionSelect(0);
                        setOpen(false);
                    }} className={`${styles.option} ${activeFilter === 0 && styles.active}`}>{translate('all', lang)}</div>
                    {!!options.length ? options.map(option => (
                        <div onClick={() => {
                            onOptionSelect(option.id)
                            setOpen(false);
                        }} key={option.id} className={`${styles.option} ${activeFilter === option.id && styles.active}`}>{option.name}</div>
                    )) : (
                        <div className={styles.noops}>{translate('no_options', lang)}</div>
                    )}
                </ul>
            )}
        </div>
    );
};

export default Filter;
