import React from 'react';
import ReactDOM from 'react-dom/client';
import { SDKProvider } from '@telegram-apps/sdk-react';
import './index.css';
import App from './App';
import {UserProvider} from "./context/UserContext";
import {FavoritesProvider} from "./context/FavoritesContext";
import {StationsProvider} from "./context/StationsContext";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <SDKProvider acceptCustomStyles debug>
          <UserProvider>
              <FavoritesProvider>
                  <StationsProvider>
                      <App />
                  </StationsProvider>
              </FavoritesProvider>
          </UserProvider>
      </SDKProvider>
  </React.StrictMode>
);
