import React, { useState } from 'react';
import {useUser} from "../../context/UserContext";

import styles from "./referralLink.module.css";
const ReferralLink = () => {
    const { user } = useUser();
    const [copied, setCopied] = useState(false);

    const referralLink = `https://t.me/RadiogrammBot?start=${user?.telegram_id}`;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralLink).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }, () => {
            console.error("Failed to copy the text to clipboard.");
        });
    };

    return (
        <div className={styles.referralContainer}>
            <a href={referralLink} className={styles.referralLink} target="_blank" rel="noopener noreferrer">
                {referralLink}
            </a>
            <button onClick={copyToClipboard} className={styles.copyButton}>
                {copied ? "Copied!" : "Copy"}
            </button>
        </div>
    );
}

export default ReferralLink;
