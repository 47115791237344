import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    ReactNode,
} from 'react';

import {getStationById, getStations, StationInterface} from "../service/stations/stations";
interface StationsContextType {
    station: StationInterface;
    stations: StationInterface[];
    handleGetStations: (lang: string) => Promise<void>;
    handleSelectStation: (id: string, lang: string) => void;
    handleGetStationById: (id: string, lang: string) => Promise<void>;
}

const StationsContext = createContext<StationsContextType | undefined>(undefined);

export const StationsProvider = ({ children }: { children: ReactNode }) => {
    const [stations, setStations] = useState<any>(null);
    const [station, setStation] = useState<any>(null);

    const params = new URLSearchParams(window.location.search)

    const handleGetStations = async (lang: string): Promise<void> => {
        try {
            const stations = await getStations(lang);

            if (stations) {
                setStations(stations);
            } else {
                console.error('Failed to fetch Stations');
            }
        } catch (error) {
            console.error('Failed to fetch Stations', error);
        }
    };

    const handleGetStationById = async (id: string, lang: string) => {
        try {
            const data = await getStationById(id, lang);
            setStation(data)
        } catch (error) {
            console.error('Failed to update stations', error);
        }
    };

    const handleSelectStation = async (id: string, lang: string) => {
        const station = await getStationById(id, lang);

        setStation(station)
    }

    useEffect(() => {
        if (params.get("lang")) {
            handleGetStations(params.get("lang") as string);
        }
    }, []);

    return (
        <StationsContext.Provider
            value={{
                station,
                stations,
                handleGetStations,
                handleSelectStation,
                handleGetStationById,
            }}
        >
            {children}
        </StationsContext.Provider>
    );
};

export const useStations = (): StationsContextType => {
    const context = useContext(StationsContext);
    if (context === undefined) {
        throw new Error('useStations must be used within an StationsProvider');
    }
    return context;
};
