import React, { FC } from 'react';
import "./userScore.css";
import { useTranslate } from '../../i18n';

const UserScore: FC<{ score: string, lang: string }> = ({ score = "0.00", lang }) => {
    const translate = useTranslate();

    return (
        <div className={"balanceWrapper"}>
            <p className={"balanceTitle"}>{translate.balance_title}</p>
            <h2 className={"balanceScore"}>{score}</h2>
            <h3 className={"balanceSubtitle"}>{translate.balance_subtitle}</h3>
        </div>
    );
};

export default UserScore;
