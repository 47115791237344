import {axiosInstance} from "../axiosConfig/axiosConfig";

export interface FavoriteStationInterface {
    "id": number,
    "user": number,
    "station": number
}

export async function getAllFavoritesByTelegramId(id: number) {
    const response = await axiosInstance.get(
        `/users/${id}/favorites/`,
    );
    return response.data;
}

export async function toggleFavorite(stationId: string, telegramId: number) {
    const response = await axiosInstance.post(
        `/toggle-favorite/?station_id=${stationId}&user_id=${telegramId}`,
        {
            telegram_id: telegramId,
            station_id: stationId
        }
    );
    return response.data;
}