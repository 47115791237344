import React, { useEffect, useState } from 'react';
import { useStations } from "../../context/StationsContext";
import { ReactComponent as NoteIcon } from "../../assets/icons/noteIcon.svg";
import { ReactComponent as FavoriteActiveIcon } from "../../assets/icons/favoriteActiveIcon.svg";
import { ReactComponent as FavoriteIcon } from "../../assets/icons/favoriteIcon.svg";
import { useUser } from "../../context/UserContext";
import { useFavorites } from "../../context/FavoritesContext";
import { getAllFavoritesByTelegramId } from "../../service/favorites/favorites";
import styles from "./stations.module.css";
import {translate} from '../../i18n';
import Search from "../../components/Search/Search";
import Filter from "../../components/Search/Filter/Filter";
import {getCountries, getGenres, StationInterface} from "../../service/stations/stations";

const Stations: React.FC<{ setStation: (newVal: number) => void, lang: "en" | "ru" }> = ({ setStation, lang }) => {
    const { user, handleGetUserByTelegramId } = useUser();
    const { favorites, handleToggleFavorite } = useFavorites();
    const { station: activeStation, stations, handleSelectStation, handleGetStations } = useStations();

    const [genres, setGenres] = useState<any[]>([]);
    const [countries, setCountries] = useState<any[]>([]);

    const [value, setValue] = useState("");
    const [activeGenre, setActiveGenre] = useState(0);
    const [activeCountry, setActiveCountry] = useState(0);

    const [filteredStations, setFilteredStations] = useState<StationInterface[]>([]);

    const handleGenreChange = (id: number) => setActiveGenre(id);
    const handleCountryChange = (id: number) => setActiveCountry(id);

    const handleChange = (val: string) => setValue(val);

    const fetchGenres = async () => {
        const data = await getGenres();
        setGenres(data)
    }

    const fetchCountries = async () => {
        const data = await getCountries();
        setCountries(data)
    }

    useEffect(() => {
        if (stations.length) {
            let filtered = stations;

            if (value) {
                filtered = filtered.filter(station =>
                    station.name.toLowerCase().includes(value.toLowerCase())
                );
            }

            if (activeGenre !== 0) {
                filtered = filtered.filter(station =>
                    station.genres.some((genre: any) => genre.id === activeGenre)
                );
            }

            if (activeCountry !== 0) {
                filtered = filtered.filter(station =>
                    station.country?.name === countries?.find(country => country.id === activeCountry).name
                );
            }

            setFilteredStations(filtered);
        }
    }, [activeGenre, activeCountry, value, stations]);

    useEffect(() => {
        fetchGenres();
        fetchCountries();
        handleGetStations(lang);
    }, []);

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <Search value={value} onChange={handleChange} lang={lang} />
            <div className={styles.filterWrapper}>
                <Filter position={"left"} activeFilter={activeGenre} title={translate('genres', lang)} options={genres} onOptionSelect={handleGenreChange} lang={lang} />
                <Filter position={"right"} activeFilter={activeCountry} title={translate('countries', lang)} options={countries} onOptionSelect={handleCountryChange} lang={lang} />
            </div>
            <div style={{ maxHeight: "100%", height: "100%", overflowY: "auto", display: "flex", flexDirection: "column", gap: "16px" }}>
                {Array.isArray(filteredStations) && !!filteredStations.length ? (
                    filteredStations.map((station) => (
                        <div onClick={() => {
                            handleSelectStation(station.id.toString(), lang)
                            setStation(0)
                        }} className={`${styles.itemWrapper} ${station.id === activeStation?.id ? styles.active : ""}`} key={station.id}>
                            <button className={styles.playButton}>
                                <NoteIcon />
                            </button>
                            <div className={styles.infoWrapper}>
                                <p className={styles.title}>{station.name}</p>
                                <p className={styles.description}>{station.description}</p>
                            </div>
                            <div className={styles.favoriteIcon} onClick={async (event) => {
                                event.stopPropagation();
                                try {
                                    await handleToggleFavorite(station?.id.toString(), user?.telegram_id)
                                    await getAllFavoritesByTelegramId(user?.telegram_id)
                                    await handleGetUserByTelegramId(user?.telegram_id.toString())
                                } catch (error) {
                                    console.error(error);
                                }
                            }}>
                                {Array.isArray(favorites) && favorites.find(fav => fav.id === station.id) ? (
                                    <FavoriteActiveIcon />
                                ) : (
                                    <FavoriteIcon />
                                )}
                            </div>
                        </div>
                    ))
                ) : (<div>{translate('no_stations', lang)}</div>)}
            </div>
        </div>
    );
};

export default Stations;
