import React, { FC } from 'react';
import ReferralLink from "../../components/ReferralLink/ReferralLink";
import styles from "./refLink.module.css";
import { translate } from '../../i18n';
import { useUser } from "../../context/UserContext";

const RefLink: FC<{ lang: "en" | "ru" }> = ({ lang }) => {
    const { user } = useUser();
    return (
        <div className={styles.pageWrapper}>
            <div className={styles.linkWrapper}>
                <p className={styles.subtitle}>{translate('your_referral_link', lang)}</p>
                <ReferralLink />
            </div>
            <div className={styles.refListWrapper}>
                <h2 className={styles.refDescription}>
                    <p>{translate('referral_bonus', lang)}</p>
                    <p>{translate('referral_bonus_2', lang)}</p>
                </h2>
                <ul className={styles.list}>
                    {Array.isArray(user.referrals) && !!user.referrals.length ? user.referrals.map((ref: any, index) => (
                        <div key={ref.id} className={styles.refItem}>
                            <span>{index + 1}. </span>
                            <p className={styles.refName}>{ref.username}</p>
                        </div>
                    )) : (
                        <div className={styles.norefs}>{translate('no_referrals', lang)}</div>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default RefLink;
