import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    ReactNode,
} from 'react';
import {getAllFavoritesByTelegramId, toggleFavorite} from "../service/favorites/favorites";
import {useUser} from "./UserContext";
import {StationInterface} from "../service/stations/stations";

interface FavoritesContextType {
    favorites: StationInterface[];
    handleGetFavoritesByUser: (id: number) => Promise<void>;
    handleToggleFavorite: (stationId: string, telegramId: number) => Promise<void>;
}

const FavoritesContext = createContext<FavoritesContextType | undefined>(undefined);

export const FavoritesProvider = ({ children }: { children: ReactNode }) => {
    const { user } = useUser();

    const [favorites, setFavorites] = useState<any>(null);

    const handleGetFavoritesByUser = async (id: number): Promise<void> => {
        try {
            const favorites = await getAllFavoritesByTelegramId(id);

            if (favorites) {
                setFavorites(favorites);
            } else {
                console.error('Failed to fetch Favorites');
            }
        } catch (error) {
            console.error('Failed to fetch Favorites', error);
        }
    };

    const handleToggleFavorite = async (stationId: string, telegramId: number) => {
        try {
            await toggleFavorite(stationId, telegramId);
            await handleGetFavoritesByUser(telegramId);
        } catch (error) {
            console.error('Failed to update favorites', error);
        }
    };

    useEffect(() => {
        if (user) {
            try {
                handleGetFavoritesByUser(user.telegram_id);
            } catch (error) {
                console.error('Failed to fetch favorites', error);
            }
        }
    }, [user]);

    return (
        <FavoritesContext.Provider
            value={{
                favorites,
                handleGetFavoritesByUser,
                handleToggleFavorite,
            }}
        >
            {children}
        </FavoritesContext.Provider>
    );
};

export const useFavorites = (): FavoritesContextType => {
    const context = useContext(FavoritesContext);
    if (context === undefined) {
        throw new Error('useFavorites must be used within an FavoritesProvider');
    }
    return context;
};
