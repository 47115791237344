import React from 'react';
import { Slider, IconButton } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { styled } from '@mui/system';

const VolumeContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '32px auto 0',
    color: '#FFFFFF'
}));

const VolumeSlider = styled(Slider)(({ theme }) => ({
    color: '#4A78F1',
    height: 8,
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
    },
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
}));

interface VolumeControlProps {
    volume: number;
    onVolumeChange: (volume: number) => void;
}

const VolumeControl: React.FC<VolumeControlProps> = ({ volume, onVolumeChange }) => {
    const handleVolumeChange = (event: Event, newValue: number | number[]) => {
        onVolumeChange(newValue as number);
    };

    return (
        <VolumeContainer>
            <IconButton onClick={() => onVolumeChange(0)} aria-label="volume">
                <VolumeUpIcon sx={{ color: '#FFFFFF' }} />
            </IconButton>
            <VolumeSlider value={volume} onChange={handleVolumeChange} aria-labelledby="continuous-slider" />
        </VolumeContainer>
    );
};

export default VolumeControl;
