import React, { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import UserScore from '../UserScore/UserScore';
import VolumeControl from '../VolumeControl/VolumeControl';
import { useUser } from '../../context/UserContext';
import { useStations } from '../../context/StationsContext';
import { useFavorites } from "../../context/FavoritesContext";
import { getAllFavoritesByTelegramId } from "../../service/favorites/favorites";
import { ReactComponent as FavoriteActiveIcon } from "../../assets/icons/favoriteActiveIcon.svg";
import { ReactComponent as FavoriteIcon } from "../../assets/icons/favoriteIcon.svg";
import { BASE_ORIGIN } from "../../service/axiosConfig/axiosConfig";

const PlayerContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: '#FFFFFF',
    padding: '20px',
    borderRadius: '10px',
}));

const ControlsContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StationName = styled(Typography)(() => ({
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
}));

const StationDescription = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: '400',
    textAlign: "center",
    opacity: '0.7',
    color: '#ffffff',
}));

const PlayerControls: React.FC<{ lang: "en" | "ru" }> = ({ lang }) => {
    const { user, setUser } = useUser();
    const { station, stations, handleSelectStation } = useStations();
    const { favorites, handleToggleFavorite, handleGetFavoritesByUser } = useFavorites();

    const [volume, setVolume] = useState(50);
    const [isPlaying, setIsPlaying] = useState(false);
    const [score, setScore] = useState<number>(0);
    const [updatedScore, setUpdatedScore] = useState(0);
    const [progress, setProgress] = useState(0);
    const pointsAccumulated = useRef(0);

    const handleVolumeChange = (newVolume: number) => {
        setVolume(newVolume);
    };

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
    };

    const handlePrevious = (id: number) => {
        const currentIndex = stations.findIndex((station) => station.id === id);
        const previousIndex = (currentIndex - 1 + stations.length) % stations.length;
        handleSelectStation(stations[previousIndex].id.toString(), lang);
        setIsPlaying(true);
    };

    const handleNext = (id: number) => {
        const currentIndex = stations.findIndex((station) => station.id === id);
        const nextIndex = (currentIndex + 1) % stations.length;
        handleSelectStation(stations[nextIndex].id.toString(), lang);
        setIsPlaying(true);
    };

    const handleProgress = (state: { playedSeconds: number }) => {
        setProgress(state.playedSeconds);
        if (station) {
            const pointsPerSecond = parseFloat(station.price_per_minute) / 60;
            pointsAccumulated.current += pointsPerSecond;
            const newScore = parseFloat(user?.wallet?.balance) + pointsAccumulated.current;
            setScore(newScore);
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const telegramId = urlParams.get('telegram_id');

        if (telegramId) {
            const ws = new WebSocket(`wss://${BASE_ORIGIN}/ws/score/${telegramId}/`);

            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.balance !== undefined) {
                    setUpdatedScore(data.balance)
                }
            };

            ws.onclose = () => {
            };

            ws.onerror = (error) => {
                console.error(error);
            };

            const intervalId = setInterval(() => {
                if (pointsAccumulated.current > 0 && isPlaying) {
                    if (ws.readyState === WebSocket.OPEN) {
                        ws.send(JSON.stringify({ points: pointsAccumulated.current }));
                        pointsAccumulated.current = 0;
                    } else {
                        console.warn('WebSocket connection is not open. Retrying...');
                    }
                }
            }, 1000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [isPlaying]);

    useEffect(() => {
        if (station) {
            // setIsPlaying(true);
        } else if (stations && !!stations.length) {
            handleSelectStation(stations[0]?.id.toString(), lang);
        }
    }, [station, stations]);

    useEffect(() => {
        if (user) {
            handleGetFavoritesByUser(user.telegram_id)
        }
    }, [user]);

    return (
        <PlayerContainer>
            <UserScore lang={lang} score={!!updatedScore ? updatedScore.toFixed(2) : score.toFixed(2)} />
            <ControlsContainer>
                <IconButton onClick={() => handlePrevious(station.id)} aria-label="previous">
                    <SkipPreviousIcon style={{ fill: '#ffffff', width: '36px', height: '36px' }} />
                </IconButton>
                <IconButton onClick={handlePlayPause} aria-label="play/pause">
                    {isPlaying ? (
                        <PauseIcon style={{ fill: '#ffffff', width: '36px', height: '36px' }} />
                    ) : (
                        <PlayArrowIcon style={{ fill: '#ffffff', width: '36px', height: '36px' }} />
                    )}
                </IconButton>
                <IconButton onClick={() => handleNext(station.id)} aria-label="next">
                    <SkipNextIcon style={{ fill: '#ffffff', width: '36px', height: '36px' }} />
                </IconButton>
            </ControlsContainer>
            <StationName>{station?.name}</StationName>
            <StationDescription>{station?.description}</StationDescription>
            <div style={{ display: "none" }}>
                <ReactPlayer
                    url={station?.url}
                    playing={isPlaying}
                    controls={false}
                    playsinline
                    volume={volume / 100}
                    width="100%"
                    height="50px"
                    onPause={() => setIsPlaying(false)}
                    onPlay={() => setIsPlaying(true)}
                    onProgress={handleProgress}
                />
            </div>
            <div style={{ cursor: "pointer", margin: "24px auto 0" }} onClick={async () => {
                try {
                    await handleToggleFavorite(station?.id.toString(), user?.telegram_id)
                    await getAllFavoritesByTelegramId(user?.telegram_id)
                } catch (error) {
                    console.error(error)
                }
            }}>
                {Array.isArray(favorites) && favorites.find(fav => fav?.id === station?.id) ? (
                    <FavoriteActiveIcon />
                ) : (
                    <FavoriteIcon />
                )}
            </div>
            <VolumeControl volume={volume} onVolumeChange={handleVolumeChange} />
        </PlayerContainer>
    );
};

export default PlayerControls;
